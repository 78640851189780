import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { titleCase, trimParam } from '../../utils';
import { IBreadCrumb } from './breadcrumb.model';
import { BreadcrumbTokenizer } from './breadcrumb.token';

/**
 * General tokenizer which handles the url paths
 */
@Injectable()
export class UrlTokenizer implements BreadcrumbTokenizer {
  protected router = inject(Router);
  path$ = new BehaviorSubject<IBreadCrumb[]>([]);

  get currentPath$() {
    return this.path$.asObservable();
  }

  connect() {
    return this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        let newPath = this.getCurrentPathFromUrl((routerEvent as NavigationEnd).urlAfterRedirects);
        const oldPath = this.path$.value;
        newPath = newPath.map((n, i) =>
          oldPath.length >= i && n.url === oldPath[i]?.url ? Object.assign(n, { params: oldPath[i].params }) : n,
        );
        this.path$.next(newPath);
      }
    });
  }

  reset() {
    // Required implementation
  }

  paramsToObject() {
    return trimParam(this.router.url.split('?')[1] || '');
  }

  protected getCurrentPathFromUrl(url: string): IBreadCrumb[] {
    // Calculate breadcrumbs from the url hierarchy
    const queryIdx = url.indexOf('?');
    const urls = url
      .substring(0, queryIdx > 0 ? queryIdx : url.length)
      .split('/')
      .filter((u) => !!u && u !== '?');
    return urls.map((u, i) => ({
      name: titleCase(u),
      url: `/${urls.slice(0, i + 1).join('/')}`,
      params: this.paramsToObject(),
    }));
  }
}
